import React from "react";
import "../components/Style/Home.scss";
import {
  Boton,
  Divcenter,
  Divcolumns,
  Divcolumn,
  Homecomponent,
  Divitem,
  Numbercontainer,
  H5Home,
  H1Home,
  H2Home,
  H3Home,
  Numberinside,
  HPHome,
  Bottomtext,
} from "./StyledComponents.js";
import LogoTipo from "../Img/Logotipo.png";
import Circleint from "../Img/Circle_Int.png";

const Home2 = () => {
  return (
    <Homecomponent className="home-component">
      <Divcolumns columns="repeat(2, 1fr)" className="Columns-first">
        <img className="logo" alt="Logotipo Int.Store" src={LogoTipo} />
      </Divcolumns>
      <Divcolumns
        gap="1%"
        columns="repeat(2, 1fr)"
        columnsmobile="repeat(1,1fr)"
      >
        <Divcenter>
          <H5Home>Sin mensualidades • Infalsificable • Fácil de usar</H5Home>
          <H1Home>LA TERMINAL MÁS EFICIENTE DEL MUNDO</H1Home>

          <H2Home>Simplifica recibir dinero</H2Home>
          <a className="enlacebtn2" href="https://int.store/registro">
            <Boton peligro onClick={() => console.log("Clic en el botón")}>
              Obtener Gratis
            </Boton>
          </a>
        </Divcenter>
        <div className="Left-Container-2">
          <img className="img-front-circle" src={Circleint} alt="Int Store" />
        </div>
      </Divcolumns>
      <Divcolumn
        backgroundcolor="rgba(255, 255, 255, 0.40);
"
        className="bottom-function"
      >
        <H3Home>¿CÓMO FUNCIONA?</H3Home>
        <Divcolumns
          columns="repeat(7,1fr)"
          columnsmobile="repeat(3,1fr)"
          gap="0%"
        >
          <Divitem>
            <Numbercontainer>
              <Numberinside>1</Numberinside>
            </Numbercontainer>
            <HPHome>Registrate</HPHome>
          </Divitem>
          <Divitem>
            <svg
              width="177"
              height="58"
              viewBox="0 0 177 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.551432 8C0.548862 10.9455 2.93459 13.3333 5.88011 13.3333C8.82563 13.3333 11.2155 10.9455 11.2181 8C11.2207 5.05448 8.83494 2.66667 5.88942 2.66667C2.9439 2.66667 0.554003 5.05448 0.551432 8ZM176.258 8.70711C176.649 8.31658 176.649 7.68342 176.259 7.29289L169.901 0.928935C169.511 0.538411 168.877 0.538411 168.487 0.928935C168.096 1.31946 168.095 1.95262 168.485 2.34315L174.137 8L168.475 13.6569C168.085 14.0474 168.084 14.6805 168.474 15.0711C168.864 15.4616 169.498 15.4616 169.888 15.0711L176.258 8.70711ZM5.88389 9H9.73995L9.7417 7H5.88564L5.88389 9ZM17.4521 9H25.1642L25.1659 7H17.4538L17.4521 9ZM32.8763 9H40.5884L40.5902 7L32.8781 7L32.8763 9ZM48.3006 9H56.0127L56.0144 7H48.3023L48.3006 9ZM63.7248 9H71.4369L71.4387 7H63.7266L63.7248 9ZM79.1491 9H86.8612L86.8629 7L79.1508 7L79.1491 9ZM94.5733 9H102.285L102.287 7H94.575L94.5733 9ZM109.998 9H117.71L117.711 7H109.999L109.998 9ZM125.422 9H133.134L133.136 7H125.424L125.422 9ZM140.846 9H148.558L148.56 7H140.848L140.846 9ZM156.27 9L163.982 9L163.984 7H156.272L156.27 9ZM171.695 9H175.551L175.552 7H171.696L171.695 9Z"
                fill="#F2F3FF"
              />
            </svg>
          </Divitem>
          <Divitem>
            <Numbercontainer>
              <Numberinside>2</Numberinside>
            </Numbercontainer>
            <HPHome>Crea tu cartera</HPHome>
          </Divitem>
          <Divitem className="hideonmobile">
            <svg
              width="177"
              height="58"
              viewBox="0 0 177 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.551432 8C0.548862 10.9455 2.93459 13.3333 5.88011 13.3333C8.82563 13.3333 11.2155 10.9455 11.2181 8C11.2207 5.05448 8.83494 2.66667 5.88942 2.66667C2.9439 2.66667 0.554003 5.05448 0.551432 8ZM176.258 8.70711C176.649 8.31658 176.649 7.68342 176.259 7.29289L169.901 0.928935C169.511 0.538411 168.877 0.538411 168.487 0.928935C168.096 1.31946 168.095 1.95262 168.485 2.34315L174.137 8L168.475 13.6569C168.085 14.0474 168.084 14.6805 168.474 15.0711C168.864 15.4616 169.498 15.4616 169.888 15.0711L176.258 8.70711ZM5.88389 9H9.73995L9.7417 7H5.88564L5.88389 9ZM17.4521 9H25.1642L25.1659 7H17.4538L17.4521 9ZM32.8763 9H40.5884L40.5902 7L32.8781 7L32.8763 9ZM48.3006 9H56.0127L56.0144 7H48.3023L48.3006 9ZM63.7248 9H71.4369L71.4387 7H63.7266L63.7248 9ZM79.1491 9H86.8612L86.8629 7L79.1508 7L79.1491 9ZM94.5733 9H102.285L102.287 7H94.575L94.5733 9ZM109.998 9H117.71L117.711 7H109.999L109.998 9ZM125.422 9H133.134L133.136 7H125.424L125.422 9ZM140.846 9H148.558L148.56 7H140.848L140.846 9ZM156.27 9L163.982 9L163.984 7H156.272L156.27 9ZM171.695 9H175.551L175.552 7H171.696L171.695 9Z"
                fill="#F2F3FF"
              />
            </svg>
          </Divitem>
          <Divitem>
            <Numbercontainer>
              <Numberinside>3</Numberinside>
            </Numbercontainer>
            <HPHome>Muéstrala</HPHome>
          </Divitem>
          <Divitem>
            <svg
              width="177"
              height="58"
              viewBox="0 0 177 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.551432 8C0.548862 10.9455 2.93459 13.3333 5.88011 13.3333C8.82563 13.3333 11.2155 10.9455 11.2181 8C11.2207 5.05448 8.83494 2.66667 5.88942 2.66667C2.9439 2.66667 0.554003 5.05448 0.551432 8ZM176.258 8.70711C176.649 8.31658 176.649 7.68342 176.259 7.29289L169.901 0.928935C169.511 0.538411 168.877 0.538411 168.487 0.928935C168.096 1.31946 168.095 1.95262 168.485 2.34315L174.137 8L168.475 13.6569C168.085 14.0474 168.084 14.6805 168.474 15.0711C168.864 15.4616 169.498 15.4616 169.888 15.0711L176.258 8.70711ZM5.88389 9H9.73995L9.7417 7H5.88564L5.88389 9ZM17.4521 9H25.1642L25.1659 7H17.4538L17.4521 9ZM32.8763 9H40.5884L40.5902 7L32.8781 7L32.8763 9ZM48.3006 9H56.0127L56.0144 7H48.3023L48.3006 9ZM63.7248 9H71.4369L71.4387 7H63.7266L63.7248 9ZM79.1491 9H86.8612L86.8629 7L79.1508 7L79.1491 9ZM94.5733 9H102.285L102.287 7H94.575L94.5733 9ZM109.998 9H117.71L117.711 7H109.999L109.998 9ZM125.422 9H133.134L133.136 7H125.424L125.422 9ZM140.846 9H148.558L148.56 7H140.848L140.846 9ZM156.27 9L163.982 9L163.984 7H156.272L156.27 9ZM171.695 9H175.551L175.552 7H171.696L171.695 9Z"
                fill="#F2F3FF"
              />
            </svg>
          </Divitem>
          <Divitem>
            <Numbercontainer>
              <Numberinside>4</Numberinside>
            </Numbercontainer>
            <HPHome>Gana Dinero</HPHome>
          </Divitem>
        </Divcolumns>
      </Divcolumn>
      <Divcolumn>
        <Bottomtext>© Intelligent Networked Transactions LLC</Bottomtext>
      </Divcolumn>
    </Homecomponent>
  );
};

export default Home2;
