import React from "react";
import "../components/Style/Registro.scss";
import {
  Bottomtext,
  DivColumnRegister,
  Divcolumns2,
  H1Home,
  HText,
  HText2,
  PurpleSubtitle,
} from "./StyledComponents";
import LogoRecibir from "./../Img/INT_Registro.png";
import Logoint from "./../Img/IconInt.png";

const Registro = () => {
  // Renderizar la salida del componente
  return (
    <div className="registro-component">
      <Divcolumns2
        className="registro-container"
        gap="0%"
        columns="repeat(2, 1fr)"
        columnsmobile="repeat(1, 1fr)"
      >
        <DivColumnRegister
          width="77%"
          alignitems="flex-start"
          className="registro-left"
>
          <img
            className="logo-recibir"
            alt="Logotipo Int.Store"
            src={LogoRecibir}
          />
          <img className="icon-int" alt="Logotipo Int.Store" src={Logoint} />
          <H1Home lineheight="6vh" fontsize="6vh">
            ¡Impulsa tus ventas con el poder de INT!{" "}
          </H1Home>
          <HText>
            INT es la herramienta ideal para incrementar tus ventas, gestionar
            pagos y enviar productos de manera fácil y segura.
          </HText>
          <Bottomtext className="btn-text">
            © Intelligent Networked Transactions LLC
          </Bottomtext>
        </DivColumnRegister>
        <DivColumnRegister className="registro-right">
          <PurpleSubtitle>Registro lista de espera</PurpleSubtitle>
          <HText2>
            Regístrate para incrementar tus ingresos de forma sencilla, en
            cuanto estemos en listos te avisaremos.
          </HText2>
          <iframe
          className="Iframe-form"
  src="https://link.superleads.mx/widget/form/AX2ylBDNIejjpZYbFKGo"
  id="inline-AX2ylBDNIejjpZYbFKGo" 
  data-layout="{'id':'INLINE'}"
  data-trigger-type="alwaysShow"
  data-trigger-value=""
  data-activation-type="alwaysActivated"
  data-activation-value=""
  data-deactivation-type="neverDeactivate"
  data-deactivation-value=""
  data-form-name="Lista de espera V1"
  data-height="400"
  data-layout-iframe-id="inline-AX2ylBDNIejjpZYbFKGo"
  data-form-id="AX2ylBDNIejjpZYbFKGo"
  title="Lista de espera V1"
      >
</iframe>
<script src="https://link.superleads.mx/js/form_embed.js"></script>
        </DivColumnRegister>
      </Divcolumns2>
    </div>
  );
};
export default Registro;
