import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Home2 from "./components/Home2";
import Registro from "./components/Registro_Principal";
import Gracias from "./components/Gracias";
import RegistroEcommerce from "./components/Registro_Ecommerce"
import RegistroTerminal from "./components/Registro_Terminal"
// import Error from "./components/Error";
import "./App.css";
import Index from "./components/Index";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Index />} />
        <Route path="/terminal" element={<Home2 />} />
        <Route path="/ecommerce" element={<Home />} />
        <Route path="/registroprincipal" element={<Registro />} />
        <Route path="/registroterminal" element={<RegistroTerminal />} />
        <Route path="/registroecommerce" element={<RegistroEcommerce />} />
        <Route path="/gracias" element={<Gracias />} />
        {/* <Route path="/error" element={<Error />} />
         */}
      </Routes>
    </Router>
  );
}

export default App;
