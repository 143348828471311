import React, { useState, useEffect } from "react";
import "../components/Style/Carousel.scss";
import Phrase1 from "../Img/Phrase1_int.png";
import Phrase2 from "../Img/Phrase2_int.png";
import Phrase3 from "../Img/Phrase3_int.png";
import Phrase4 from "../Img/Phrase4_int.png";
import Phrase5 from "../Img/Phrase5_int.png";
import Phrase6 from "../Img/Phrase6_int.png";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const items = [Phrase1, Phrase2, Phrase3, Phrase4, Phrase5, Phrase6];

  const showItem = (index) => {
    if (index < 0) {
      setCurrentIndex(items.length - 1);
    } else if (index >= items.length) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(index);
    }
  };

  const nextItem = () => {
    showItem(currentIndex + 1);
  };

  const prevItem = () => {
    showItem(currentIndex - 1);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextItem();
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [currentIndex]); // Se ejecutará cada vez que currentIndex cambie

  return (
    <div className="carousel-container">
      <div className="carousel">
        {items.map((item, index) => (
          <div
            key={index}
            className={`carousel-item ${
              index === currentIndex ? "active" : ""
            }`}
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            <img src={item} alt={`Imagen ${index + 1}`} />
          </div>
        ))}
      </div>
      <div className="dot-container">
        {items.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => showItem(index)}
          ></span>
        ))}
      </div>
      <button className="prev" onClick={prevItem}>
        &#10094;
      </button>
      <button className="next" onClick={nextItem}>
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;
