import React from "react";
import "../components/Style/Home2.scss";
import "../components/Style/flexed-columns.scss";
import {
  Boton,
  Divcenter,
  Divcolumns,
  Divcolumn,
  Homecomponent,
  H5Home,
  H1Home,
  Bottomtext,
} from "./StyledComponents.js";
import LogoTipo from "../Img/Logotipo.png";
import Terminal from "../Img/Elipse_1.png";
import Ecomerce from "../Img/Elipse_2.png";

const Index = () => {
  return (
    <Homecomponent className="home-component homeindex">
      <Divcolumns columns="repeat(2, 1fr)" className="Columns-first">
        <img className="logo" alt="Logotipo Int.Store" src={LogoTipo} />
      </Divcolumns>
      <Divcolumns gap="0%" columns="100%" columnsmobile="repeat(1,1fr)">
        <Divcenter className="flexed-columns">
          <H5Home className="sin-mensualidades">
            • Sin mensualidades • Intuitiva • Rápida{" "}
          </H5Home>
          <H1Home className="potencializa">Potencializa</H1Home>
          <H1Home marginf="2vh auto 3vh" fontsize="7.2vh" className="tunegocio">
            ¡TU NEGOCIO!
          </H1Home>
          <H5Home className="text-light">
            Habilitalo para la venta en línea y el cobro en ubicaciones físicas
            sin necesidad de una terminal bancaria
          </H5Home>
          <a className="enlacebtn2" href="https://int.store/registro">
            {" "}
            <Boton
              bwidth="40%"
              bmarg="1em auto"
              peligro
              onClick={() => console.log("Clic en el botón")}
            >
              ¡Registrate Ahora!
            </Boton>
          </a>
        </Divcenter>
      </Divcolumns>
      <Divcolumns className="Botom-container-items" columns="48% 48%" gap="4%">
        <div className="Item-Home">
          <Divcolumns
            columnsmobile="100%"
            gapmobile="0%"
            columns="30% 60%"
            gap="10%"
            className="ind-columns"
          >
            <img src={Terminal} alt="Terminal digital" />
            <div className="text-cont1">
              <H5Home className="title-titem">TERMINAL DIGITAL</H5Home>
              <a className="Links-Landings" href="https://int.store/terminal">
                <Boton bwidth="auto" className="btn-links" colort="#F2F3FF">
                  Ver más
                </Boton>
              </a>
            </div>
          </Divcolumns>
        </div>
        <div className="Item-Home">
          <Divcolumns
            columnsmobile="100%"
            gapmobile="0%"
            columns="30% 60%"
            gap="10%"
            className="ind-columns"
          >
            <img src={Ecomerce} alt="Terminal digital" />
            <div className="text-cont1">
              <H5Home className="title-titem"> E-COMMERCE EN SEGUNDOS</H5Home>
              <a className="Links-Landings" href="https://int.store/ecommerce">
                <Boton bwidth="auto" className="btn-links" colort="#F2F3FF">
                  Ver más
                </Boton>
              </a>
            </div>
          </Divcolumns>
        </div>
      </Divcolumns>

      <Divcolumn>
        <Bottomtext>© Intelligent Networked Transactions LLC</Bottomtext>
      </Divcolumn>
    </Homecomponent>
  );
};

export default Index;
