import React from "react";
import "../components/Style/Registro.scss";
import {
  Bottomtext,
  DivColumnRegister,
  Divcolumns,
  Divcolumns2,
  HText3,
  ItemSocial,
  PurpleSubtitle,
} from "./StyledComponents";
import LogoRecibir from "./../Img/INT_Registro.png";
import Logoint2 from "./../Img/Star_coin.png";
import Carousel from "./Carousel";
import Whatsapp from "./../Img/Whatsapp.png";
import Telegram from "./../Img/Telegram.png";
import X from "./../Img/x.png";
import Messenger from "./../Img/messenger.png";
import Facebook from "./../Img/facebook.png";
import Compartir from "./../Img/compartir.png";

const Gracias = () => {
  // Renderizar la salida del componente
  return (
    <div className="registro-component">
      <Divcolumns2
        className="registro-container"
        gap="0%"
        columns="50% 50%"
        columnsmobile="100%"
      >
        <DivColumnRegister
          width="77%"
          alignitems="center"
          className="registro-left alineacionmobile"
        >
          <img
            className="logo-recibir"
            alt="Logotipo Int.Store"
            src={LogoRecibir}
          />
          <img className="icon-int" alt="Logotipo Int.Store" src={Logoint2} />
          <div className="carousel-conti">
            <Carousel />
          </div>

          <Bottomtext className="btn-text">
            © Intelligent Networked Transactions LLC
          </Bottomtext>
        </DivColumnRegister>
        <DivColumnRegister className="registro-right alineacionmobile2">
          <PurpleSubtitle>Registro lista de espera</PurpleSubtitle>

          <HText3>Comparte con tus amig@s para crecer juntos</HText3>
          <Divcolumns
            gap=".5%"
            className="Social-columns"
            columns="repeat(6,1fr)"
          >
            <ItemSocial href="https://api.whatsapp.com/send?text=Echa un vistazo a esto: https://int.store">
              <img src={Whatsapp} alt="Compartir en Whatsapp" />
            </ItemSocial>
            <ItemSocial href="https://api.whatsapp.com/send?text=Echa un vistazo a esto: https://int.store">
              <img src={Telegram} alt="Compartir en Telegram" />
            </ItemSocial>
            <ItemSocial href="https://api.whatsapp.com/send?text=Echa un vistazo a esto: https://int.store">
              <img src={X} alt="Compartir en X" />
            </ItemSocial>
            <ItemSocial href="https://api.whatsapp.com/send?text=Echa un vistazo a esto: https://int.store">
              <img src={Messenger} alt="Compartir en Messenger" />
            </ItemSocial>
            <ItemSocial href="https://api.whatsapp.com/send?text=Echa un vistazo a esto: https://int.store">
              <img src={Facebook} alt="Compartir en Facebook" />
            </ItemSocial>
            <ItemSocial href="https://api.whatsapp.com/send?text=Echa un vistazo a esto: https://int.store">
              <img src={Compartir} alt="Compartir" />
            </ItemSocial>
          </Divcolumns>
        </DivColumnRegister>
      </Divcolumns2>
    </div>
  );
};
export default Gracias;
